// utils/videoHelpers.ts
import { Video, Image } from "../types";

/**
 * Fix Storyblok asset URLs for CORS compatibility
 * @param asset Asset URL or object with filename property
 * @returns CORS-safe URL
 */
export function getCorsSafeUrl(asset: any): string {
  if (!asset) return "";

  // Handle Storyblok asset objects (which have a filename property)
  if (typeof asset === "object" && asset.filename) {
    const url = asset.filename;
    if (url.includes("https://a.storyblok.com")) {
      return url.replace("https://a.storyblok.com", "https://s3.amazonaws.com/a.storyblok.com");
    }
    return url;
  }

  // Handle string URLs directly
  if (typeof asset === "string" && asset.includes("https://a.storyblok.com")) {
    return asset.replace("https://a.storyblok.com", "https://s3.amazonaws.com/a.storyblok.com");
  }

  // Return the original value if it's not a Storyblok asset URL
  return asset;
}

/**
 * Prepare a video object with CORS-safe URLs
 * @param video The original video object
 * @returns A new video object with CORS-safe URLs
 */
export function prepareCorsFixedVideo(video: Video): Video {
  if (!video) return video;

  return {
    ...video,
    // Don't modify videoLink as it's a YouTube URL
    // Fix Storyblok asset URLs
    thumbnail: video.thumbnail ? { ...video.thumbnail, filename: getCorsSafeUrl(video.thumbnail) } : video.thumbnail,
    poster: video.poster ? { ...video.poster, filename: getCorsSafeUrl(video.poster) } : video.poster,
    preview: video.preview ? { ...video.preview, filename: getCorsSafeUrl(video.preview) } : video.preview,
  };
}

/**
 * Format video duration from seconds to MM:SS format
 * @param seconds Duration in seconds
 * @returns Formatted duration string
 */
export function formatVideoDuration(seconds: number): string {
  if (!seconds) return "0:00";

  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, "0")}`;
}

/**
 * Format video duration in ISO 8601 format for JSON-LD
 * @param minutes Duration in minutes
 * @param seconds Duration in seconds
 * @returns ISO 8601 formatted duration string (e.g., PT1H30M15S)
 */
export function formatIso8601Duration(minutes: number, seconds: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  let duration = "PT";
  if (hours > 0) duration += `${hours}H`;
  if (remainingMinutes > 0) duration += `${remainingMinutes}M`;
  if (remainingSeconds > 0) duration += `${remainingSeconds}S`;

  return duration;
}

/**
 * Extract video ID from YouTube URL
 * @param url YouTube URL
 * @returns YouTube video ID or null if not found
 */
export function getYouTubeVideoId(url: string): string | null {
  if (!url) return null;

  // Handle youtu.be format
  if (url.includes("youtu.be")) {
    const parts = url.split("/");
    return parts[parts.length - 1].split("?")[0];
  }

  // Handle youtube.com format
  const match = url.match(/[?&]v=([^&#]*)/);
  return match ? match[1] : null;
}

/**
 * Get a thumbnail URL from a YouTube video ID
 * @param videoId YouTube video ID
 * @param quality Thumbnail quality (default: high)
 * @returns YouTube thumbnail URL
 */
export function getYouTubeThumbnailUrl(
  videoId: string,
  quality: "default" | "high" | "medium" | "standard" | "maxres" = "high",
): string {
  if (!videoId) return "";
  return `https://img.youtube.com/vi/${videoId}/${quality}default.jpg`;
}
